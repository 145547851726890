import React, { useContext, useEffect } from 'react';

import { AuthContext } from '../../../contexts/AuthContext';

import { ErrorPage } from '../../shared/ErrorPage';

const FourOhOne = () => {
  const { user, logout } = useContext(AuthContext);

  useEffect(() => {
    if (user) {
      logout();
    }
  }, [user, logout]);

  return (
    <ErrorPage
      seoTitle="401: Unauthorized"
      title="Odhlásili jsme Vás"
      description="Bezpečnost je u nás na prvním místě. Vzhledem k delší době neaktivity, jsme Vás museli z bezpečnostních důvodů odhlásit."
    />
  );
};

export default FourOhOne;
